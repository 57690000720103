/**** Header page CSS ***/

a{
  cursor: pointer;
}
.header-background {
  background-color: #0271be;
  height: 100%;
  position: sticky;
  display: flex;
  top:0;
  z-index: 999;
  margin: auto;
  align-items: center;
}
.timeText {
  font-size: 32px;
  font-weight: 500;
}
.dayText .yearText{
  font-size: 20px;
  font-weight: 400;
}

.image{
  height: 65px;
}

.btn-dark{
  background-color: #091B21 !important;
}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: #0d6efd !important;
}
.btn-group .btn, .btn-group .btn:hover, .btn-group .btn:active, .btn-group .btn:not(:hover) {
  background-color: #091B21;
  color: white;
  border-color: #272E30;
}

/* Media query for screens smaller than 768px (e.g., tablets) */
@media (max-width: 768px) {
  .timeText{
    font-size: 2rem;
    font-weight: 800;
  }
  .dayText{
    font-size: 1.2rem;
  }
  .yearText{
    font-size: 0.825rem;
  }
}

/* Media query for screens smaller than 576px (e.g., mobile phones) */
@media (max-width: 576px) {
  .headerContentDiv .btn-group {
    position: sticky;
    z-index: 999;
  }
  .logo-container img {
    width: auto;
  }
  .timeText {
    font-size: 10px;
    font-weight: 400;
  }
  .dayText {
    font-size: 10px;
  }
  .yearText {
    font-size: 10px;
  }

  .image{
    height: 32px;
  }

  .btn-group .btn {
    font-size: 12px;
  }
}