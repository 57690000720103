.line-color{
    color:#ffffff;
}
.recharts-legend-wrapper{
    width: 100% !important;
    padding-top: 25px;
}
.recharts-wrapper{
    width: 100% !important;
}

.selected-text-size{
    font-size: 12px;
}
  
.chart-height{
    height: 48vh;
}
.t-wrap {
    white-space: nowrap;
  }

  .visitors-table-scrol{
    height:60vh;
    overflow-y: auto;
    overflow-x: auto;
    border-radius: 0.938rem;
  }
@media (max-width: 576px) {
    .padding-mobile {
      padding: 15px; /* Adjust the padding value according to your design */
    }
}  

@media (min-width: 768px) and (max-width: 991.98px) {
    .t-wrap {
      white-space: normal;
    }
  }
  /* Styles for screens smaller than 768px (typical mobile devices) */
@media (max-width: .98px) {
  .t-wrap {
      white-space: normal; 
  }
}

@media screen and (min-width: 1400px) and (max-width: 1630px) {
  .chart-margin-align {
    margin-bottom: 2px !important;
  }
}
