.table-scrol{
    height:50vh;
    overflow-y: auto;
    border-radius: 0.938rem;
}

.label-table-scrol{
    height:50vh;
    overflow-y: auto;
    border-radius: 0.938rem;
}

.from-scrol{
    height: 65vh;
    overflow-y: auto;
    overflow-x:hidden;
    border-radius: 0.938rem;
}

.visitorlog-table-scrol{
    height:44vh;
    overflow-y: auto;
    overflow-x: auto;
    border-radius: 0.938rem;
}

@media(max-width: 1600px){
    .visitorlog-table-scrol{
        height:39vh;
    }
    .table-scrol{
        height:45vh;
    }
}

@media(max-width: 1400px){
    .visitorlog-table-scrol{
        height:35vh;
    }
}

@media(max-width: 770px){
    .visitorlog-table-scrol{
        height:40vh;
        overflow-y: auto;
        overflow-x: auto;
        border-radius: 0.938rem;
    }
    
}

@media(max-width: 760px){
    .visitorlog-table-scrol{
        height:30vh;
        overflow-y: auto;
        overflow-x: auto;
        border-radius: 0.938rem;
    }
    
}

@media(max-width: 500px){
    .label-table-scrol{
        height:40vh;
        overflow-y: auto;
        border-radius: 0.938rem;
    }
    
}

@media(max-width: 400px){
    .visitorlog-table-scrol{
        height:15vh;
    }
    
}
