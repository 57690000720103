/* Media query for screens smaller than 768px (e.g., tablets) */
@media (max-width: 768px) {
  .mainDiv {
    overflow: hidden;
  }
}

/* Media query for screens smaller than 576px (e.g., mobile phones) */
@media (max-width: 576px) {
  .mainDiv {
    overflow: hidden;
  }
  .checkin-logo{
    height: 42px;
  }
}
