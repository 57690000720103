.terms-scrol{
  min-height: 40vh;
  max-height: 65vh;
  overflow-y: auto;
  overflow-x:hidden;
  border-radius: 0.938rem;
}
  /* Media query for screens smaller than 576px (e.g., mobile phones) */
@media (max-width: 576px) {

  .ageryContentTextOne{
    font-size: 0.813rem;
  }
  .aceptContent{
    font-size: 0.7rem;
  }

  }

  .custom-modal-backdrop {
    background-color: rgba(171, 218, 245); /* Change the background color here */
  }