.outlet-height{
  height: 75vh !important;
}

.outlet-scrollbar{
  overflow-y: auto;
  overflow-x: hidden;
}

@media (min-width: 320px) and (max-width: 480px) {
  .sidepanel {
    height: 25px !important;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .sidepanel {
    height: 25px !important;
  }
}

@media (min-width: 576px) and (max-width: .98px) {
  .sidepanel {
    height: 25px !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .sidepanel {
    height: 25px !important;
  }
}

@media (min-width: 820px) and (max-width: 1180px) {
  .sidepanel {
    height: 25px !important;
  }
}
