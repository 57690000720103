.checkouttable {
  color: white;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 93, 224, 0.5) 0%,
    rgba(0, 176, 252, 0.5) 100%
  );
  backdrop-filter: blur(7.5px);
  border-radius: 0.938rem;
  position: relative;
}

.checkouttable th {
  color: #01e2d5;
  padding: 12px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  position: sticky;
  z-index: 1000;
  top: 0;
  background-color: #04558c;
}

.table-wrapper {
  height: 64vh;
  overflow-y: auto;
  border-radius: 0.938rem;
}

.checkouttable th::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px; /* Set the height of your border line */
  background-color: #01e2d5; /* Set the color of your border line */
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.checkouttable td {
  border-bottom: 1px solid white;
  padding: 6px;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.filter td {
  font-weight: 300 !important;
}
.checkoutbutton {
  color: white;
  border-radius: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background-light-primary-main, #0a77ff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  padding: 6px 37px;
  border: none;
}

.checkouttable tbody tr:last-child td {
  border-bottom: none;
}

.table-hover.table-hover tr:hover {
  background-color: #21a1db !important;
}

@media (max-width: 1300px) {
  .table-wrapper {
    height: 52vh;
  }
}

/* Media query for screens smaller than 576px (e.g., mobile phones) */
@media (max-width: 576px) {
  .table-wrapper {
    height: 30vh;
    width: 100%;
    display: block;
    overflow: scroll;
  }
  .checkouttable th {
    border-bottom: 1px solid white;
    color: #fff;
    font-size: 0.613rem;
    text-align: center;
  }
  .checkouttable td {
    padding: 0.313rem;
    font-size: 0.625rem;
  }
  .checkoutbutton {
    width: 6rem;
    height: 1.5rem;
  }
}
