.profile-scroll {
  height: 55vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.width-input {
  width: 26vh;
}

.image-height {
  height: 100% !important;
}

@media only screen and (min-device-width: 450px) and (max-device-width: 849px) {
  .profile-scroll {
    height: 40vh !important;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .width-input {
    width: 26vh;
  }

  .image-height {
    height: 50% !important;
  }
}

@media only screen and (min-device-width: 850px) and (max-device-width: 1024px) {
  .profile-scroll {
    height: 45vh !important;
    /* Adjusted height value for iPad screens */
    overflow-y: auto;
    overflow-x: hidden;
  }

  .width-input {
    width: 22vh;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 449px) {
  .profile-scroll {
    height: 50vh !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
