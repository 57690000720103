/*** Common Page CSS ***/
/* dev akash */
html,
body {

  font-family: 'Inter', sans-serif !important;
 
}

.bg-image {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-attachment: fixed !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-blend-mode: multiply;
}


.mainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  overflow: hidden;
}

.btn-primary {
  background-color: #01e2d5 !important;
  border: none !important;
  color: #000 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

/* .btn-secondary {
  background-color: #2a7be4 !important;
  border: none !important;
  width: 82%;
  font-size: 13px !important;
} */

.text-center h1 {
  font-weight: 400;
  font-size: 2rem;
  color: #ffff;
  margin-bottom: 0;
  margin-top: 7px;
}



.btn-sign-in {
  margin-top: 1rem;
  background: #01e2d5 !important;
  width: 106%;
  height: 100%;
  color: #000 !important;
  font-family: Inter;
  font-size: 15px !important;
  border: none !important;
  border-radius: 5px;
}

.card {
  background: linear-gradient(
    90deg,
    rgba(0, 93, 224, 0.5) 0%,
    rgba(0, 176, 252, 0.5) 100%
  ) !important;
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
}

.text-color {
  color: white;
  font-size: 15px;
  font-weight: 600;
}

.text-color span {
  font-size: 36px;
}

.form-control.is-invalid {
  border: none;
  background-image: none !important;
  border-color: #ffff;
}
input.form-control.is-invalid:focus {
  border: none;
  border-color: #fff;
  box-shadow: none;
}

/* Remove red border and box shadow for password field when not focused */
input.form-control.is-invalid {
  border: none;
  background-image: none !important;
  border-color: #fff;
}

input.form-control:focus {
  border-color: #091b21;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(9, 27, 33, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(9, 27, 33, 0.6);
}


/* ////////////////////////search screnn compoenent-end////////////////// */



/* ////////////////////////videscrenn compoenent////////////////// */
.videocard {
  height: 598px !important;
  width: 806px !important;
  background: linear-gradient(
    90deg,
    rgba(0, 93, 224, 0.5) 0%,
    rgba(0, 176, 252, 0.5) 100%
  );
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
}

.videoheading {
  padding-top: 40px;
}

.watchvideo {
  color: #fff;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
}

.videocontent {
  width: 635px !important;
  height: 357px !important;
  background-color: rgb(170, 158, 158);
  border-radius: 10px;
}

.videocontent video {
  width: 100%;
  /* Make the video width 100% of its container */
  height: 100%;
  /* Make the video height 100% of its container */
  object-fit: cover;
  border-radius: 10px;
  /* Maintain the aspect ratio and cover the container */
}

.videocontentbutton {
  height: 46px;
  width: 161px;
  background-color: #01e2d5;
  font-size: 17px;
  font-weight: 600;
  border: none;
  border-radius: 6px;
}

/* ////////////////////////videscrenn compoenent-end////////////////// */

/* ////////////////////////agreescreen compoenent////////////////// */

.endcontent-text {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

/* -------- Media query - Personalinfo------------ */
@media (max-width: 1440px) {
  .personal-bg {
    margin-top: 274px !important;
    margin-left: 406px !important;
  }
  .mainDiv{
    overflow-y: hidden;
  }
}
@media (max-width: 834px) {
  .personal-bg {
    margin-top: 280px !important;
    margin-left: 105px !important;
  }
  .mainDiv{
    overflow-y: hidden;
  }
}


/* Fields center - medium screen */
@media (max-width: 834px){
.field{
   display: flex;
   justify-content: center;

  }
}