.visitor-Details-scroll {
  min-height: 30vh;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.width-input {
  width: 26vh;
}

.date-time-width {
  width: 18vh;
}

@media only screen and (max-width: 767px) {
  .visitor-scroll {
    min-height: 30vh !important;
    max-height: 45vh !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .width-input {
    width: 26vh;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .visitor-scroll {
    min-height: 30vh !important;
    max-height: 45vh !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .width-input {
    width: 22vh;
  }
}
