.icon {
  vertical-align: middle;
  line-height: 5px;
  font-size: 25px;
}
.icon_header {
  vertical-align: middle;
  line-height: 1px;
  font-size: 26px;
}
.icon,
.icon_header {
  margin-right: 15px;
}

.close_icon {
  color: rgb(247, 238, 238);
  margin-left: 30px;
  margin-top: 10px;
  cursor: pointer;
}

.grid-container {
  display: grid;
  grid-template-columns: 260px 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    "sidebar header header header"
    "sidebar main main main";
  height: fit-content;
}

.active-width {
  width: 98%;
}

.header {
  grid-area: header;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 22px;
  /* box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35); */
  display: none;
}

.menu-icon {
  display: none;
  padding-top: 10px;
  padding-left: 20px;
  color: white;
}

#sidebar {
  grid-area: sidebar;
  height: 75vh;
  background-color: transparent;
  overflow-y: hidden;
  transition: all 0.8s;
  -webkit-transition: all 0.8s;
  border-right: 1px solid #5fbde7;
}

.scrol-sidebar {
  height: 60vh;
  overflow-y: auto;
}
.sidebar-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px 0px 30px;
  margin-bottom: 30px;
}

.sidebar-title > span {
  display: none;
}

.sidebar-brand {
  padding-left: 30px;
  font-size: 20px;
  font-weight: 700;
  color: white;
}

.sidebar-list {
  padding: 0;
  list-style-type: none;
}

.sidebar-list .sidebar-list-item {
  padding: 10px 20px;
  font-size: 25px;
}

.sidebar-list-item:hover {
  color: rgb(255, 255, 255) !important;
  background-color: #113441;
  border-right: 4px solid white;
  cursor: pointer;
  width: 98% !important;
}

.bg-link {
  text-decoration: none !important;
  color: rgb(255, 255, 255) !important;
  font-size: 18px !important;
}

a .icon {
  margin-right: 24px;
  margin-left: 12px;
}

.sidebar-responsive {
  display: inline !important;
  position: absolute;
  z-index: 12 !important;
}

.main-container {
  grid-area: main;
  overflow-y: auto;
  padding: 20px 20px;
  color: rgba(255, 255, 255, 0.95);
}

.main-title {
  display: flex;
  justify-content: space-between;
}

/* .sidebar-list-item:active {
  color: rgb(255, 255, 255) !important;
  background-color: #091b21;
  border-right: 4px solid white;
} */

.active-link {
  color: rgb(255, 255, 255) !important;
  background-color: #091b21;
  border-right: 4px solid white;
}

@media only screen and (max-width: 992px) {
  .grid-container {
    display: block;
  }

  #sidebar {
    display: none;
    height: 100%;
    background-color: #0271be;
  }

  .menu-icon {
    display: inline-flex;
  }

  .sidebar-title > span {
    display: block;
  }
  .header {
    display: block;
  }
}

@media only screen and (max-width: 992px) {
  .grid-container {
    display: block;
  }

  #sidebar {
    grid-area: sidebar;
    background-color: #0271be;
  }
  .main-cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }
  .header {
    height: 0vh;
    padding: 0;
  }
}

@media only screen and (max-width: 576px) {
  #sidebar {
    grid-area: sidebar;
    width: 75vw;
    background-color: #0271be;
  }

  .sidebar-list .sidebar-list-item {
    padding: 20px 40px;
    font-size: 25px;
  }

  .sidebar-list-item a .icon {
    margin-right: 35px;
  }
  .header {
    height: 0;
  }
}
