
/* form screen css */
.customCard {
  background: linear-gradient(
    90deg,
    rgba(0, 93, 224, 0.5) 0%,
    rgba(0, 176, 252, 0.5) 100%
  );
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
}

.check-in-form-scrol{
  min-height: auto;
  max-height: 65vh;
  overflow-y: auto;
  overflow-x:hidden;
}
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

/* Media query for screens smaller than 1024px (e.g., tablets) */
@media (max-width: 1024px) {
  .customCard {
    margin-top: 5rem !important;
  }
  
}

/* Media query for screens smaller than 768px (e.g., tablets) */
@media (max-width: 600px) and (min-width: 500px) {
  .mainDiv {
    overflow-y: hidden;
  }
}

/* Media query for screen in zoom size*/
@media only screen and (max-width:1600px) and (min-width: 810px){
  .mainDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    height: 86vh;
}

.header-h{
  height: 13vh !important;
}
}

@media only screen and (max-width: 500px){
  .mainDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
}
  
}
