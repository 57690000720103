.links {
  color: white;
  text-decoration: none;
}

#head-section {
  margin: 15px 8px;
}

.nav-dashboard {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  margin-left: 25px;
  margin-right: 25px;
}

.nav-dashboard #button {
  outline: none;
  border: none;
  color: black;
  background: #01e2d5;
}

.btn-outline-primary {
  background-color: transparent !important;
  border: none !important;
}

.toggle-button {
  background-color: transparent !important;
  border: none !important;
  color: #0271be;
}

.active {
  background-color: #ffffff !important;
  color: #0271be !important;
  border-radius: 4px !important;
}

.transparent-table {
  background-color: transparent;
}

.th-color {
  color: #01e2d5 !important;
}

.active-btn {
  width: 50% !important;
}

.bg-transparent th {
  color: #01e2d5;
  padding: 12px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  position: sticky;
  z-index: 5 !important;
  top: 0;
  background-color: #04558c !important;
}

@media (max-width: 412px) {
  .fnt {
    font-size: 12px;
  }
  .active-btn {
    width: 85% !important;
  }
}
@media (max-width: 780px) {
  .fnt {
    font-size: 12px;
  }
  .active-btn {
    width: 100% !important;
  }
}
@media (max-width: 580px) {
  .fnt {
    font-size: 12px;
  }
  .active-btn {
    width: 100% !important;
  }
}
@media (max-width: 364px) {
  .fnt {
    font-size: 12px;
  }
  .active-btn {
    width: 100% !important;
  }
}
