/*** Capture Screen CSS ***/

.left-card {
    object-fit: cover;
    width: 100% !important;
    height: 35vh !important;
  }
  
  .camSec {
    width: 45vw;
  }

  .before-capture {
    background-color: #d9d9d9;
  }

  .capture-btn {
    border-radius: var(--main-system-24-px, 24px);
    background: var(--theme-color-bg-primary, #2a7be4);
    width: 132px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    height: 34px;
    color: white;
    border: none;
  }
  
  .capture-btn-margin {
    margin-top: -16px;
  }

  @media only screen and (max-width: 650px) {
    .camSec {
      display: block;
      width: 45vw;
    }
    .left-card {
      object-fit: cover;
      width: 100% !important;
      height: 23vh !important;
    }
    .text-center span {
      color: #ffff;
      font-size: 15px;
    }
  }
  @media only screen and (max-width: 1023px) and (min-width: 700px) {
    .left-card {
      object-fit: cover;
      width: 100% !important;
      height: 23vh !important;
    }
  }
  @media only screen and (max-width: 1280px) and (min-width: 1024px) {
    .left-card {
      object-fit: cover;
      width: 100% !important;
      height: 35vh !important;
    }
  }

  @media only screen and (max-width: 380px) {
    .camSec {
      display: block;
      width: 60vw;
    }
  }